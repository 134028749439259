@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
@import "react-datepicker/dist/react-datepicker.css";
@import "scroll-hint/css/scroll-hint.css";
@import "photoswipe/dist/photoswipe.css";
@import "photoswipe/dist/default-skin/default-skin.css";

@import "components/blog";
@import "components/nprogress";
@import "components/react-datepicker";
@import "components/read-more";
@import "components/scrollbar";
@import "components/slick";
@import "components/table-sticky";
@import "components/tiptap";
@import "components/typography";

p {
  &:empty {
    height: 1rem;
  }
}

a {
  &.link {
    color: var(--chakra-colors-blue-700);
    text-decoration: underline;

    &:hover {
      color: var(--chakra-colors-brand-600);
    }
  }

  &.link,
  &.chakra-link {
    &[target="_blank"]:not(.no-external-icon):after {
      content: "";
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20aria-hidden%3D%22true%22%20focusable%3D%22false%22%20data-prefix%3D%22fas%22%20data-icon%3D%22external-link-alt%22%20class%3D%22svg-inline--fa%20fa-external-link-alt%20fa-w-16%22%20role%3D%22img%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cpath%20fill%3D%22%232c5282%22%20d%3D%22M432%2C320H400a16%2C16%2C0%2C0%2C0-16%2C16V448H64V128H208a16%2C16%2C0%2C0%2C0%2C16-16V80a16%2C16%2C0%2C0%2C0-16-16H48A48%2C48%2C0%2C0%2C0%2C0%2C112V464a48%2C48%2C0%2C0%2C0%2C48%2C48H400a48%2C48%2C0%2C0%2C0%2C48-48V336A16%2C16%2C0%2C0%2C0%2C432%2C320ZM488%2C0h-128c-21.37%2C0-32.05%2C25.91-17%2C41l35.73%2C35.73L135%2C320.37a24%2C24%2C0%2C0%2C0%2C0%2C34L157.67%2C377a24%2C24%2C0%2C0%2C0%2C34%2C0L435.28%2C133.32%2C471%2C169c15%2C15%2C41%2C4.5%2C41-17V24A24%2C24%2C0%2C0%2C0%2C488%2C0Z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
      width: 12px;
      height: 12px;
      margin: 0 2px;
      display: inline-block;
      background-repeat: no-repeat;
      background-size: contain;
    }
  }
}

.wysiwyg {
  ul,
  ol {
    margin-inline-start: 1.2rem;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  hr {
    margin: 1rem 0;
  }
}
