.table-sticky {
  border-collapse: separate !important;

  thead {
    th {
      background-color: white;
      position: sticky;
      top: 0;
      z-index: 10;

      &:first-child {
        left: 0;
        z-index: 20;
      }
    }
  }

  &.-left {
    tbody {
      td:first-child {
        position: sticky;
        left: 0;
        background-color: white;
        z-index: 10;
      }
    }
  }
}
