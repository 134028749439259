.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;

    &-thumb {
      background-color: var(--chakra-colors-brand-400);
    }
  }
}
