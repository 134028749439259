.ProseMirror:focus {
  outline: none;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: var(--chakra-colors-gray-400);
  pointer-events: none;
  height: 0;
}
