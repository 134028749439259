h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold !important;
}

html {
  h1 {
    font-size: var(--chakra-fontSizes-h1);
  }

  h2 {
    font-size: var(--chakra-fontSizes-h2);
  }

  h3 {
    font-size: var(--chakra-fontSizes-h3);
  }

  h4 {
    font-size: var(--chakra-fontSizes-h4);
  }

  h5 {
    font-size: var(--chakra-fontSizes-h5);
  }

  h6 {
    font-size: var(--chakra-fontSizes-h6);
  }
}

mark {
  background-color: transparent;
  color: var(--chakra-colors-purple-500);
}
