.read-more {
  &__anchor {
    color: var(--chakra-colors-brand-500);

    &.-block {
      display: block;
    }

    &:hover {
      text-decoration: underline;
    }
  }
}
