.slick-prev {
  left: -40px;
}

.slick-next {
  right: -40px;
}

.slick-arrow {
  width: var(--chakra-space-8);
  height: var(--chakra-space-8);
  @apply w-8 h-8;

  &:before {
    color: var(--chakra-colors-gray-400);
    font-size: var(--chakra-fontSizes-3xl);
    @apply text-primary text-3xl;
  }
}

.slick-thumbnails {
  .slick-slide {
    padding-left: var(--chakra-space-1);

    > div {
      display: flex;
      border-width: 3px;
      border-color: transparent;
    }
  }

  .slick-current {
    > div {
      border-color: var(--chakra-colors-primary);
      border-width: 3px;
    }
  }
}
