.react-datepicker {
  &__navigation-icon {
    &::before {
      box-sizing: content-box;
      top: 11px;
    }
  }
}

.react-datepicker-popper {
  z-index: var(--chakra-zIndices-dropdown);
}
