$primary-light: var(--chakra-colors-brand-200);
$primary: var(--chakra-colors-primary);
$body-bg: var(--chakra-colors-gray-50);

.blog__contents {
  line-height: 2;

  h2 {
    margin-bottom: var(--chakra-space-4);
    padding-left: var(--chakra-space-2);
    padding-right: var(--chakra-space-2);
    padding-top: var(--chakra-space-3);
    padding-bottom: var(--chakra-space-3);
    background-color: var(--chakra-colors-primary);
    font-weight: var(--chakra-fontWeights-bold);
    font-size: 1.5rem;
    background-image: linear-gradient(
      -45deg,
      #fff 25%,
      $primary-light 25%,
      $primary-light 50%,
      #fff 50%,
      #fff 75%,
      $primary-light 75%,
      $primary-light
    );
    background-size: 5px 5px;
    border-left: 5px solid $primary;
  }

  h3 {
    margin-bottom: var(--chakra-space-4);
    padding-left: var(--chakra-space-2);
    padding-right: var(--chakra-space-2);
    background-color: var(--chakra-colors-primary);
    font-weight: var(--chakra-fontWeights-bold);
    color: #fff;
    position: relative;
    height: 30px;
    font-size: 1.3rem;
    line-height: 30px;

    &:after {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 0;
      height: 0;
      height: 30px;
      content: "";
      border-color: transparent $body-bg transparent transparent;
      border-style: solid;
      border-width: 15px 15px 15px 0;
    }
  }

  h4 {
    margin-bottom: var(--chakra-space-4);
    font-weight: var(--chakra-fontWeights-bold);
    font-size: 1.2rem;

    &::after {
      display: block;
      height: 4px;
      content: "";
      background-image: linear-gradient(
        -45deg,
        #fff 25%,
        $primary 25%,
        $primary 50%,
        #fff 50%,
        #fff 75%,
        $primary 75%,
        $primary
      );
      background-repeat: repeat;
      -webkit-background-size: 4px 4px;
      background-size: 4px 4px;
    }
  }

  h5 {
    margin-bottom: var(--chakra-space-4);
    font-weight: var(--chakra-fontWeights-bold);
    font-size: 1.2rem;

    &::before {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 5px;
      content: "";
      background: $primary;
    }
  }

  p {
    margin-bottom: 1rem;

    + h2,
    + h3,
    + h4,
    + h5,
    + h6 {
      margin-top: 2rem;
    }
  }
}
